import axios from "../util/AxiosInstance";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Marker } from "react-map-gl";
import Pin from "../components/Pin";
import bbox from "@turf/bbox";
import Map from "../components/Map";
import { Box } from "@mui/system";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ShareDialog from "../components/ShareDialog";
import getURL from "../util/URL";
import UserMenu from "../components/user/Menu";
import IOSSwitch from "../components/display/IOSSwitch";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import { pink } from "@mui/material/colors";
import PostDialog from "../components/post/PostDialog";
import mapStore from "../store/mapStore";
import userStore from "../store/userStore";
import { motion } from "framer-motion";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Helmet } from "react-helmet";
import { CircularProgress } from "@mui/material";
import Supercluster from "supercluster";

const makeGeoJson = (contents) => {
  const features = contents.map((content) => {
    return {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Point",
        coordinates: [content.location.lat, content.location.lng],
      },
    };
  });

  return {
    type: "FeatureCollection",
    features,
  };
};

const calculateDelay = (index, total) => {
  const baseDelay = 0.1;
  const maxDelay = 2.0;
  const delayFactor = Math.max(baseDelay, maxDelay / total);
  return index * delayFactor;
};

//MUI input box 및 border CSS 하얀색으로 변경하는 CSS
const whiteColorSx = {
  "& label": {
    // placeholder text color
    color: "white",
  },
  "& label.Mui-focused": {
    // 해당 input focus 되었을 때 placeholder text color
    // floatng label을 사용할 때 처리 필요하다
    color: "white",
  },
  "& label.Mui-error": {
    color: "#d32f2f",
  },
  "& .MuiOutlinedInput-root": {
    color: "white",
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "#1876D2",
    },
  },
  "& .css-1ptx2yq-MuiInputBase-root-MuiInput-root:before": {
    borderBottom: "1px solid white",
  },
  "& .css-1ptx2yq-MuiInputBase-root-MuiInput-root:after": {
    borderBottom: "2px solid white",
  },
  "& .css-1x51dt5-MuiInputBase-input-MuiInput-input": {
    color: "white",
  },
  ".css-mnn31": {
    color: "white",
  },
};

const CONTAINER_STYLE = (x) => ({
  position: "fixed",
  width: "20%",
  height: "100%",
  margin: "0 auto",
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  transform: `translate(${x}%, -45%)`,
});

const pinVariants = {
  hidden: { opacity: 0, y: -50, scale: 0.5 },
  visible: { opacity: 1, y: 0, scale: 1 },
};

const setBoundsByContents = (contentsData, setBounds) => {
  if (contentsData.length === 0) {
    return;
  }

  const geoJson = makeGeoJson(contentsData);

  const zoom = 1;

  // 클러스터링을 위한 Supercluster 인스턴스 생성
  const supercluster = new Supercluster({
    log: true,
    radius: 60,
    maxZoom: zoom,
    minZoom: zoom,
  }).load(geoJson.features);

  // Zoom level 1에서 전체 지구를 포함하는 bbox
  const bbox = [
    -180, // 서쪽 경도
    -85, // 남쪽 위도 (85도 남위)
    180, // 동쪽 경도
    85, // 북쪽 위도 (85도 북위)
  ];

  const clusters = supercluster.getClusters(bbox, zoom);

  const maxCluster = clusters.reduce((acc, cur) => {
    const accPeoperties = acc.properties;
    const curPeoperties = cur.properties;

    if (!accPeoperties?.point_count) {
      return cur;
    }

    if (!curPeoperties?.point_count) {
      return acc;
    }

    return accPeoperties.point_count > curPeoperties.point_count ? acc : cur;
  });

  let latList = contentsData.map((content) => content.location.lat);
  let lngList = contentsData.map((content) => content.location.lng);

  if (maxCluster.properties.cluster_id) {
    const leaves = supercluster.getLeaves(maxCluster.properties.cluster_id);
    latList = leaves.map((d) => d.geometry.coordinates[0]);
    lngList = leaves.map((d) => d.geometry.coordinates[1]);
  }

  const minLat = Math.min(...latList);
  const maxLat = Math.max(...latList);

  const minLng = Math.min(...lngList);
  const maxLng = Math.max(...lngList);

  setBounds([
    [minLng, minLat],
    [maxLng, maxLat],
  ]);
};

function MapHook({ queryContents }) {
  const { setAccessToken } = userStore();
  /* 
     Mappin에서 사용하는 username외의 instagram에서 사용하는 username;
     instagram_graph_user_profile 권한을 받으려면 백엔드에서
     사용자 인스타그램 profile을 불러와서 사용한더라도, 화면에서 보여야 검수 통과가 가능하다.
    */
  const { setFeedId } = userStore();

  //Mappin에서 사용하는 username
  const { username } = userStore();

  //사용자 컨텐츠 로딩 중인지 아닌지 확인하는 상태값
  const [loading, setLoading] = useState(true);

  //사용자 컨텐츠 로딩 중인지 아닌지 확인하는 상태값
  const [uiLoading, setUiLoading] = useState(false);

  //외부 팝업 정보
  //사용자가 지도상의 Pin을 클릭했을 때 팝업으로 나오는 컨텐츠 값
  const { popup, setPopup } = mapStore();

  //인스타그램 oauth 인증 후 redirect 할 때 같이 넘어오는 query param
  const [code, setCode] = useState(null);

  //인스타그램 oauth 인증 후 redirect 할 때 같이 넘어오는 query param
  // const [contents, setContents] = useState(queryContents ? queryContents : []);

  const { contents, setContents } = mapStore();

  //게시글 상세 정보 설정
  const { setPopupInfo } = mapStore();

  //유저 상세 메뉴 element
  //유저 상세 메뉴 element 지정
  const { anchorEl, setAnchorElByEvent } = mapStore();

  //게시글 작성 popup open
  const { postDialogOpen } = mapStore();

  //지도 축적 설정
  const [bounds, setBounds] = useState(null);

  //지도 element reference
  const mapRef = useRef();

  //외부 팝업 정보가 있을 경우 listner 지정하는 useEffect
  useEffect(() => {
    //외부 팝업 정보가 없을 경우 아무것도 하지 않음
    if (!popup) {
      return;
    }

    //외부 팝업을 위한 listner 선언
    const githubOAuthCodeListener = (e) => {
      // 동일한 Origin 의 이벤트만 처리하도록 제한
      if (e.origin !== window.location.origin) {
        return;
      }

      //listner가 받은 event에 data에서 code를 가져옴
      const { code } = e.data;

      //code가 있을 경우
      if (code) {
        //인스타그램에서 제공하는 code 파라미터 state 설정
        setCode(code);
        //oauth 팝업 닫음
        popup.close();
        //외부 팝업 정보 초기화
        setPopup(null);
      }
    };

    //외부 팝업에 message라는 이름으로 상단 listner 지정
    window.addEventListener("message", githubOAuthCodeListener, false);

    return () => {
      //외부 팝업 닫힐 시 message listner 삭제
      window.removeEventListener("message", githubOAuthCodeListener);
    };
  }, [code, popup]);

  useEffect(() => {
    //외부 팝업 정보가 없을 경우 아무것도 하지 않음
    if (!code) {
      return;
    }

    //instagram api 호출 전 사용자에게 loading 화면 보여줌
    setLoading(true);

    //instagram api 호출
    axios
      .get(`/instagramSync`, {
        params: { code },
      })
      .then((response) => {
        const data = response.data;

        //instagram feedId 가져옴 (instagram pk 라고 생각해도 될 듯)
        const feedId = data.socialUsername;

        //instagram feed 컨텐츠 state 설정
        setContents(data.contents);
        //instagram feed ID state 설정
        setFeedId(feedId);
        //instagram feed 컨텐츠가 추가 된 만큼 boundary 재조정 (화면 축적)
        setBoundsByContents(data.contents, setBounds);
      });
  }, [code]);

  //화면 첫 진입 시 필수적으로 해야하는 useEffect
  useEffect(() => {
    //api 호출을 통해 사용자 컨텐츠를 가져온다
    //공유하기 기능을 통해 들어온 경우 api 호출 없이 queryParam 컨텐츠 만으로 지도 재 조정
    //공유하기 기능을 통해 들어오지 않은 경우 api 호출하여 컨텐츠 로드 후 지도 재 조정
    const promies =
      contents.length !== 0
        ? new Promise((resolve) => {
            resolve(contents);
          })
        : axios
            .get("")
            .then((response) => {
              if (!response.data.length) {
                setLoading(false);
                setUiLoading(true);
              }
              setContents(response.data);
              return response.data;
            })
            .catch((error) => {
              setAccessToken(null);
              localStorage.setItem("longTerm", false);
            });

    promies.then((data) => setBoundsByContents(data, setBounds));
  }, []);

  //contents state가 변경되지 않은 한 해당 component는 렌더링 되지 않도록 useMemo를 사용하였다.
  const pins = useMemo(
    () =>
      contents.map((content, index, arr) => (
        <Marker
          key={`marker-${index}`}
          longitude={content.location.lng}
          latitude={content.location.lat}
          anchor="bottom"
          onClick={(e) => {
            e.originalEvent?.stopPropagation();
            const target = {
              center: [e.target._lngLat.lng, e.target._lngLat.lat],
            };
            mapRef.current.flyTo({
              ...target,
              duration: 2000,
              offset: [-130, 0],
              essential: true,
            });
            setPopupInfo(content);
          }}
        >
          <motion.div
            initial="hidden"
            animate="visible"
            variants={pinVariants}
            transition={{
              duration: 0.8,
              delay: calculateDelay(index, arr.length),
              type: "spring",
              stiffness: 100,
            }}
            style={{
              position: "absolute",
              transform: "translate(-50%, -100%)", // 핀의 중심을 위치에 맞추기 위해 사용
              top: 0,
              left: 0,
            }}
            onAnimationComplete={() => {
              if (index === arr.length - 1) {
                setUiLoading(true);
              }
            }}
            whileHover={{ scale: 1.5 }}
            whileTap={{ scale: 0.9 }}
          >
            <Pin />
          </motion.div>
        </Marker>
      )),
    [contents, setPopupInfo]
  );

  return (
    <>
      <Helmet>
        <title>{username ? `${username}님의 지도` : "Mappin"}</title>
        <meta
          name="description"
          content="당신의 기록을 지도의 표시할 수 있습니다."
        />
        <meta
          name="keywords"
          content="여행기록, SNS, 소셜미디어, 지도, Map, Mappin"
        />
        <meta name="robots" content="index, follow" />
        <meta charset="UTF - 8" />
        <meta http-equiv="Content-Script-Type" content="Text/javascript" />
        <meta http-equiv="X-UA-Compatible" content="IE-edge" />
        <meta http-equiv="Subject" content="Social Media" />
      </Helmet>
      <Map
        setLoading={setLoading}
        bounds={bounds}
        scrollZoom={uiLoading}
        dragPan={uiLoading}
        onClick={(event) => {
          event.originalEvent?.stopPropagation();

          const features = event.features;

          if (!features) {
            return;
          }

          const feature = features[0];
          const [minLng, minLat, maxLng, maxLat] = bbox(feature);
          mapRef.current.fitBounds(
            [
              [minLng, minLat],
              [maxLng, maxLat],
            ],
            { padding: 40, duration: 1000 }
          );
        }}
        pins={loading ? null : pins}
        ref={mapRef}
      >
        {uiLoading ? (
          <>
            <div style={CONTAINER_STYLE(-230)}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  marginLeft: "30px",
                  marginTop: "80px",
                }}
              >
                <Tooltip
                  title="Account settings"
                  style={{ marginBottom: "10px" }}
                >
                  <IconButton
                    onClick={setAnchorElByEvent}
                    size="small"
                    aria-controls={anchorEl ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={anchorEl ? "true" : undefined}
                  >
                    <Avatar sx={{ width: 32, height: 32 }}>
                      {username ? username[0].toUpperCase() : ""}
                    </Avatar>
                  </IconButton>
                </Tooltip>
                <IOSSwitch pins={pins} />
              </Box>
              <UserMenu />
              <ShareDialog url={getURL(contents)} />
            </div>
            <div
              style={(() => {
                const style = CONTAINER_STYLE(200);
                style.flexDirection = "row";
                return style;
              })()}
            >
              <>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <SearchIcon sx={{ color: "white", mr: 1, my: 0.5 }} />
                  <TextField
                    sx={whiteColorSx}
                    label="Search"
                    variant="standard"
                  />
                </Box>
                <IconButton aria-label="delete" onClick={postDialogOpen}>
                  <AddLocationAltIcon
                    sx={{ color: pink[500] }}
                    fontSize="large"
                  />
                </IconButton>
                <PostDialog />
              </>
            </div>
          </>
        ) : (
          <div
            style={(() => {
              const style = CONTAINER_STYLE(200);
              style.flexDirection = "row";
              return style;
            })()}
          >
            <CircularProgress size={40} disableShrink />
          </div>
        )}
      </Map>
    </>
  );
}

export default MapHook;
