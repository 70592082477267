import LZString from "lz-string";

function getURL(contents) {
  const compressed = LZString.compressToEncodedURIComponent(
    JSON.stringify(contents)
  );

  const url = window.location.href;

  return `${url}?contents=${compressed}`;
}

export default getURL;
