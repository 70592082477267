import React, { useState, useMemo, useEffect } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import postStore from "../../store/postStore";
import ExifReader from "exifreader";
import { IconButton, ImageListItemBar } from "@mui/material";
import { Close, Delete } from "@mui/icons-material";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ErrorTextStyle = {
  color: "#d32f2f",
  fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  fontEeight: 400,
  fontSize: "0.75rem",
  lineHeight: 1.66,
  letterSpacing: "0.03333em",
  textAlign: "left",
  marginTop: "3px",
  marginRight: "14px",
  marginBottom: 0,
  marginLeft: "14px",
};

const UploadPicuture = () => {
  const { files, setFiles } = postStore();

  const { buttonColor, setButtonColor } = postStore();

  const [images, setImages] = useState([]);

  const { errorMessage, setErrorMessage } = postStore();

  const { setLatitude, setLongitude } = postStore();
  useEffect(() => {
    return () => {
      setImages([]);
    };
  }, []);

  // useEffect(() => {
  //   [...files].forEach((file) => {
  //     const reader = new FileReader();

  //     reader.onload = (e) => {
  //       const src = e.target.result;
  //       const tags = ExifReader.load(src);

  //       tags.then((tag) => {
  //         console.log(tag);

  //         const GPSLongitude = tag?.GPSLongitude;

  //         if (GPSLongitude) {
  //           setLongitude(GPSLongitude.description);
  //         }

  //         const GPSLatitude = tag?.GPSLatitude;

  //         if (GPSLatitude) {
  //           setLatitude(GPSLatitude?.description);
  //         }
  //       });

  //       setImages((prev) => [
  //         ...prev,
  //         {
  //           name: file.name,
  //           src: src,
  //         },
  //       ]);
  //     };

  //     reader.readAsDataURL(file);
  //   });
  // }, [files]);
  useEffect(() => {
    files.forEach((file) => {
      if (!images.some((img) => img.name === file.name)) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const src = e.target.result;
          const tags = ExifReader.load(src);

          tags.then((tag) => {
            console.log(tag);

            const GPSLongitude = tag?.GPSLongitude;
            if (GPSLongitude) {
              setLongitude(GPSLongitude.description);
            }

            const GPSLatitude = tag?.GPSLatitude;
            if (GPSLatitude) {
              setLatitude(GPSLatitude.description);
            }
          });

          setImages((prev) => [
            ...prev,
            {
              name: file.name,
              src: src,
            },
          ]);
        };

        reader.readAsDataURL(file);
      }
    });
  }, [files]);

  const onFileUpload = (event) => {
    event.preventDefault();

    const isDuplicated = [...files].some((file) =>
      [...event.target.files].some((newFile) => newFile.name === file.name)
    );

    if (isDuplicated) {
      setButtonColor("error");
      setErrorMessage("중복된 파일이 있습니다.");
      return;
    }

    setButtonColor("primary");
    setImages([]);
    setFiles([...files, ...event.target.files]);
  };

  const handleDeleteimg = (img) => {
    const updatedImages = images.filter((image) => image.name !== img.name);
    const updatedFiles = files.filter((file) => file.name !== img.name);
    setImages(updatedImages);
    setFiles(updatedFiles);
  };

  return (
    <React.Fragment>
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        color={buttonColor}
      >
        Upload file
        <VisuallyHiddenInput
          type="file"
          multiple
          onChange={onFileUpload}
          accept="image/*"
          required
        />
      </Button>
      {buttonColor === "error" && <p style={ErrorTextStyle}>{errorMessage}</p>}
      <ImageList sx={{ width: 500, height: "auto" }} cols={3} rowHeight="auto">
        {images.map((item) => {
          return (
            <ImageListItem key={item.name}>
              <img src={`${item.src}`} alt={item.name} loading="lazy" />
              <ImageListItemBar
                sx={{
                  background:
                    "linear-gradient(to bottom, rgba(255,255,255,0.5) 0%, " +
                    "rgba(255,255,255,0.3) 70%, rgba(255,255,255,0) 100%)",
                }}
                position="top"
                actionIcon={
                  <IconButton
                    sx={{ color: "gray" }}
                    onClick={() => handleDeleteimg(item)}
                  >
                    <Close />
                  </IconButton>
                }
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    </React.Fragment>
  );
};

export default UploadPicuture;
