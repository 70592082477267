import React, { useState, useEffect, useRef } from "react";
import Map from "../components/Map";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import axios from "../util/AxiosInstance";
import moveMapRight from "../util/Intervals";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import JoinHook from "./JoinHook";
import JSEncrypt from "jsencrypt";
import { FormHelperText } from "@mui/material";
import userStore from "../store/userStore";
import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

//MUI input box 및 border CSS 하얀색으로 변경하는 CSS
const whiteColorSx = {
  "& label": {
    // placeholder text color
    color: "white",
  },
  "& label.Mui-focused": {
    // 해당 input focus 되었을 때 placeholder text color
    // floatng label을 사용할 때 처리 필요하다
    color: "white",
  },
  "& label.Mui-error": {
    color: "#d32f2f",
  },
  "& .MuiOutlinedInput-root": {
    color: "white",
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "#1876D2",
    },
  },
};

const nameErrorText = "아이디를 입력 해 주세요.";

const pwErrorText = "비밀번호를 입력 해 주세요.";

const doLogin = (login) => axios.post(`/login`, login);

function LoginHook() {
  /* 
    given : 사용자 브라우저 localStorage에 longTerm과 accessToken
    when : 과거에 로그인 유지 버튼을 클릭한 상태로 로그인 한 경험이 있을 경우
    then : 사용자 브라우저에 저장된 장기 유지 토큰을 이용하여 로그인한다.
  */
  const { setAccessToken } = userStore();

  //업데이트 가능 여부 -> 24시간에 한번 씩 외부 소셜 미디어에서 정보 가져오기 가능
  const { setUpdating } = userStore();

  //Mappin에서 사용하는 username
  const { setUsername } = userStore();

  /* 
   Mappin에서 사용하는 username외의 instagram에서 사용하는 username;
   instagram_graph_user_profile 권한을 받으려면 백엔드에서
   사용자 인스타그램 profile을 불러와서 사용한더라도, 화면에서 보여야 검수 통과가 가능하다.
  */
  const { setFeedId } = userStore();

  //로그인 화면 첫 진입 시 http 전송 중 보안을 위해 비밀번호 암호화를 위한 식별키
  const [uuid, setUuid] = useState(null);

  //로그인 화면 첫 진입 시 http 전송 중 보안을 위해 비밀번호 암호화를 공개키
  const [publicKey, setPublicKey] = useState(null);

  //로그인 유지 여부
  const [longTerm, setLongTerm] = useState(false);

  //인스타그램 연동할 경우 oauth로그인을 위한 popup
  const [popupOpen, setPopupOpen] = useState(false);

  const [nameError, setNameError] = useState(false);

  const [nameHelperText, setNameHelperText] = useState("");

  const [passwordError, setPasswordError] = useState(false);

  const [pwHelperText, setPwHelperText] = useState("");

  const [loginError, setLoginError] = useState(false);

  const [loginUsername, setLoginUsername] = useState("");

  const [password, setPassword] = useState("");

  const { joinComplete, setJoinComplete } = userStore();

  const { joinCompleteText } = userStore();

  const { joinCompleteOnClose, setJoinCompleteOnClose } = userStore();

  //mapbox 조작을 위한 reference
  const mapRef = useRef();

  //프로세스 정상 진행을 위한 초기 작업
  const initilize = () => {
    return axios.get(`/join`).then((response) => {
      const data = response.data;
      setUuid(data.uuid);
      setPublicKey(data.publicKey);
      return data;
    });
  };

  const clearState = () => {
    setUuid(null);
    setPublicKey(null);
    setNameHelperText("");
    setPasswordError(false);
    setPwHelperText("");
    setLoginError(false);
    setLoginUsername("");
    setPassword("");
  };

  //초기 진입 시 해야 할 작업을 useEffect with no dependancy를 이용합니다.
  useEffect(() => {
    //백그라운드 지도가 오른쪽으로 조금씩 이동하게 합니다.
    const id = setInterval(() => moveMapRight(mapRef), 100);

    setJoinCompleteOnClose((event, reason) => {
      /* 
        사용자가 content editor를 이용해 장문의 게시글을 작성하던 중 실수로 백그라운드 
        혹은 ecs 키를 잘못 눌러 게시글 작성이 중단되는 경우를 방지하기 위함
      */
      if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
        setJoinComplete(false);
      }
    });

    initilize();
    return () => {
      //Component가 unmount될 때 해야할 작업
      clearInterval(id);
      clearState();
    };
  }, []);

  return (
    <Map
      ref={mapRef}
      scrollZoom={false}
      dragPan={false}
      doubleClickZoom={false}
    >
      <Container component="main" maxWidth="xs" style={{ width: "80%" }}>
        <Box
          component="form"
          onSubmit={(event) => {
            event.preventDefault();

            const login = {};

            if (!loginUsername) {
              setNameError(true);
              setNameHelperText(nameErrorText);
              return;
            }

            login.username = loginUsername;

            if (!password) {
              setPasswordError(true);
              setPwHelperText(pwErrorText);
              return;
            }

            // Encrypt with the public key...
            const encrypt = new JSEncrypt();
            encrypt.setPublicKey(publicKey);
            const encrypted = encrypt.encrypt(password);

            login.password = encrypted;

            login.uuid = uuid;
            login.isLongTerm = longTerm;

            const callBack = (response) => {
              setAccessToken(response.data.accessToken);
              localStorage.setItem("accessToken", response.data.accessToken);
              setUpdating(response.data.updating);
              setUsername(response.data.username);
              localStorage.setItem("username", response.data.username);
              localStorage.setItem("longTerm", longTerm);
              setFeedId(response.data.feedId);
              setUuid(null);
              setPublicKey(null);
            };

            doLogin(login)
              .then(callBack)
              .catch((error) => {
                if (error.response.status === 409) {
                  return initilize()
                    .then((data) => {
                      // Encrypt with the public key...
                      const encrypt = new JSEncrypt();
                      encrypt.setPublicKey(data.publicKey);
                      const encrypted = encrypt.encrypt(password);

                      login.password = encrypted;

                      login.uuid = data.uuid;
                      login.isLongTerm = longTerm;

                      return doLogin(login);
                    })
                    .then(callBack);
                }
                setLoginError(true);
              });

            setPopupOpen(false);
          }}
          noValidate
          sx={{ width: "auto" }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="ID"
            name="username"
            autoComplete="username"
            autoFocus
            error={nameError}
            helperText={nameHelperText}
            sx={whiteColorSx}
            value={loginUsername}
            onChange={(e) => {
              const target = e.target;
              setLoginUsername(target.value);

              if (!target.value) {
                setNameError(true);
                setNameHelperText(nameErrorText);
                return;
              }

              setNameError(false);
              setNameHelperText("");
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={passwordError}
            helperText={pwHelperText}
            sx={whiteColorSx}
            value={password}
            onChange={(e) => {
              const target = e.target;

              setPassword(target.value);

              if (!target.value) {
                setPasswordError(true);
                setPwHelperText(pwErrorText);
                return;
              }

              setPasswordError(false);
              setPwHelperText("");
            }}
          />
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                color="default"
                style={{ zIndex: 1, color: "white", fontWeight: 600 }}
                value="start"
                label="로그인 유지"
                labelPlacement="start"
                control={
                  <Checkbox
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "white",
                      },
                    }}
                    onChange={(e) => {
                      setLongTerm(e.target.checked);
                    }}
                  />
                }
              />
            </FormGroup>
            <FormHelperText
              sx={{ display: loginError ? "block" : "none" }}
              error={loginError}
              id="my-helper-text"
            >
              아이디 또는 비밀번호가 일치하지 않습니다.
            </FormHelperText>
          </FormControl>
          <IconButton
            color="primary"
            aria-label="Move to Sample page"
            onClick={() => (window.location.href = "https://m3in.com/sample/")}
          >
            <HelpIcon />
          </IconButton>
          <Button
            type="submit"
            fullWidth
            sx={{ mb: 2, ...whiteColorSx }}
            value={"로그인"}
          />
          <Grid container style={{ position: "relative" }}>
            <Grid item xs>
              <Link variant="body2">{"비밀번호 찾기"}</Link>
            </Grid>
            <Grid item>
              <Link
                onClick={() => {
                  setPopupOpen(true);
                  setNameHelperText("");
                  setPasswordError(false);
                  setPwHelperText("");
                  setLoginError(false);
                  setLoginUsername("");
                  setPassword("");
                }}
                variant="body2"
              >
                {"회원가입"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <JoinHook
        popupOpen={popupOpen}
        setPopupOpen={setPopupOpen}
        publicKey={publicKey}
        uuid={uuid}
      />
      <Dialog
        open={joinComplete}
        onClose={joinCompleteOnClose}
        aria-labelledby="complete-dialog-title"
        aria-describedby="complete-dialog-description"
      >
        <DialogTitle id="complete-dialog-title">{"회원가입 완료"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="complete-dialog-description">
            {joinCompleteText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={joinCompleteOnClose}
            autoFocus
            value={"확인"}
          />
        </DialogActions>
      </Dialog>
    </Map>
  );
}

export default LoginHook;
