import React, { useState, useMemo, useEffect, useCallback } from "react";
import postStore from "../../store/postStore";
import { Chip, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "../../util/AxiosInstance";
import { debounce } from "lodash";

const LocationRegistration = () => {
  const { location, setLocation } = postStore();
  const { latitude, setLatitude, longitude, setLongitude } = postStore();
  const { errorMessage, setErrorMessage } = postStore();

  const [keyword, setKeyword] = useState("");
  const [result, setResult] = useState([]);

  // 사용자 위치 정보를 가져와서 주변위치 list를 load시 보여줌
  useEffect(() => {
    setErrorMessage("");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position);
          setLatitude(position.coords.longitude);
          setLongitude(position.coords.latitude);
          loadLocation();
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            // 사용자가 위치 정보에 대한 권한을 거부한 경우
            // setStatus('허용 안함');
            console.log("허용안함");
          } else {
            // 다른 오류 처리
            // setStatus('오류');
            console.error(
              "위치 정보를 가져오는 중 오류가 발생했습니다.",
              error
            );
          }
        }
      );
    }
  }, [latitude, longitude]);

  const loadLocation = () => {
    axios
      .get("/location/nearby", {
        params: { x: latitude, y: longitude },
      })
      .then((response) => {
        console.log(response.data);
        setResult(response.data.documents);
      });
  };

  // 위치 검색 debounce 적용
  const handleLocationDebounce = useCallback(
    debounce(async (params) => {
      axios
        .get("/location", {
          params: { query: params },
        })
        .then((response) => {
          const filterData = response.data.documents.filter((item) =>
            item.place_name.toLowerCase().includes(params.toLowerCase())
          );

          setResult(filterData);
        });
    }, 300),
    []
  );

  //위치검색 / onkeyup으로 인한 정규식 추가 - 한글,영어,숫자의 value 값만 있을 때 keyup event 적용
  const handleLocationChange = (e) => {
    const regex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]+$/;
    const { value } = e.target;
    if (regex.test(value)) {
      setKeyword(value);

      handleLocationDebounce(value);
    }
  };

  // load 또는 검색 결과 클릭 / 선택한 위치정보를 chip에 보여주기 위함
  const handleResultClick = (name, id, x, y, address) => {
    setLocation({ name: name, id: id, x: x, y: y, address });
  };

  // 선택한 위치정보 삭제
  const handleDelete = () => {
    setLocation({});
  };

  return (
    <React.Fragment>
      {location.name ? (
        <Chip label={location.name} onDelete={handleDelete} sx={{ mb: 1 }} />
      ) : (
        <Typography variant="body2" fontWeight={"bold"} mb={1} color={"error"}>
          {errorMessage}
        </Typography>
      )}
      <TextField
        id="standard-search"
        label="위치검색"
        type="search"
        variant="standard"
        fullWidth
        onKeyUp={handleLocationChange}
        autoComplete="false"
        defaultValue={keyword}
      />
      <Box sx={{ height: 300, overflowX: "auto" }}>
        {result.length > 0 ? (
          result.map((data) => (
            <div
              key={data.id}
              onClick={() =>
                handleResultClick(
                  data.place_name,
                  data.id,
                  data.x,
                  data.y,
                  data.address_name
                )
              }
            >
              <Typography variant="subtitle1" my={1} sx={{ cursor: "pointer" }}>
                {data.place_name}
              </Typography>
            </div>
          ))
        ) : (
          <Typography variant="subtitle1" textAlign={"center"} my={1}>
            검색결과가 없습니다.
          </Typography>
        )}
      </Box>
    </React.Fragment>
  );
};

export default LocationRegistration;
