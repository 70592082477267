import React, { useRef } from "react";
import postStore from "../../store/postStore";
import { Editor } from "@toast-ui/react-editor";

const WriteCaption = () => {
  const { caption, setCaption } = postStore();

  // react-editor 에서 onchange시 value 값을 가져오기위해 필요
  const editorRef = useRef();

  // editor 내용 change
  const handleCaptionChange = () => {
    const editorInstance = editorRef.current.getInstance();
    const htmlContent = editorInstance.getHTML();
    setCaption(htmlContent);
  };
  return (
    <React.Fragment>
      <Editor
        ref={editorRef}
        // initialValue=""
        previewStyle="vertical"
        height="600px"
        initialEditType="wysiwyg"
        useCommandShortcut={false}
        name="content"
        initialValue={caption}
        onChange={handleCaptionChange}
      />
    </React.Fragment>
  );
};

export default WriteCaption;
