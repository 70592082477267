import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import axios from "../util/AxiosInstance";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/TextField";
import JSEncrypt from "jsencrypt";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import userStore from "../store/userStore";
import throttle from "../util/Throttle";

function JoinHook({ popupOpen, setPopupOpen, publicKey, uuid }) {
  //Mappin에서 사용하는 username
  const { username, setUsername } = userStore();

  const { email, setEmail } = userStore();

  const [nameError, setNameError] = useState(false);

  const [nameHelperText, setNameHelperText] = useState("");

  const [emailError, setEmailError] = useState(false);

  const [emailHelperText, setEmailHelperText] = useState("");

  const [password, setPassword] = useState("");

  const [errorElement, setErrorElement] = useState([]);

  const { setJoinComplete, setJoinCompleteText } = userStore();

  const idRef = useRef();

  // throttle 콜백 함수 정의
  const throttledCheckDuplicate = useMemo(
    () =>
      throttle((username, idRef) => {
        if (!username) {
          setNameError(true);
          setNameHelperText("아이디를 입력해 주세요.");
          setErrorElement((cur) => [
            ...cur.filter((c) => c !== idRef?.current),
            idRef?.current,
          ]);
          return;
        }
        axios
          .get(`/join/isDuplicated`, {
            params: { username },
          })
          .then((response) => {
            const data = response.data;
            if (data.isDuplicated) {
              setNameError(true);
              setNameHelperText("중복된 아이디입니다.");
              setErrorElement((cur) => [
                ...cur.filter((c) => c !== idRef?.current),
                idRef?.current,
              ]);
              return;
            }
            setNameError(data.isDuplicated);
            setNameHelperText("사용 가능한 아이디입니다.");
          });
      }, 3000),
    []
  );

  // useCallback 훅으로 콜백 함수 정의
  const checkDuplicate = useCallback(
    (e) => {
      e.preventDefault();
      throttledCheckDuplicate(username, idRef);
    },
    [username, throttledCheckDuplicate]
  );

  const onSubmit = useCallback(
    throttle((event) => {
      event.preventDefault();

      if (nameError || !password) {
        if (errorElement?.length > 0) {
          errorElement[0].focus();
        }
        return;
      }

      const join = {};

      // Encrypt with the public key...
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(publicKey);
      const encrypted = encrypt.encrypt(password);

      join.uuid = uuid;
      join.email = email;
      join.username = username;
      join.password = encrypted;

      axios
        .post(`/join`, join)
        .then((response) => {
          setPopupOpen(false);
          setJoinCompleteText(
            `회원가입 신청이 완료되었습니다. \n 이메일 인증을 완료해 주세요.`
          );
          setJoinComplete(true);
        })
        .catch(() => {});
    }, 10000),
    [nameError, password, errorElement, publicKey, password, uuid, username]
  );

  useEffect(() => {
    if (!popupOpen) {
      setUsername("");
      setNameError(false);
      setNameHelperText("");
      setEmailHelperText("");
      setPassword("");
    }
  }, [popupOpen]);

  return (
    <Dialog
      open={popupOpen}
      onClose={(event, reason) => {
        /* 
          사용자가 content editor를 이용해 장문의 게시글을 작성하던 중 실수로 백그라운드 
          혹은 ecs 키를 잘못 눌러 게시글 작성이 중단되는 경우를 방지하기 위함
        */
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          setPopupOpen(false);
        }
      }}
      PaperProps={{
        component: "form",
        onSubmit: onSubmit,
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        회원가입
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Mappin을 이용하시려면 아이디와 비밀번호를 입력 해 주세요.
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="email"
          name="email"
          label="Email"
          type="email"
          fullWidth
          variant="standard"
          sx={{ width: "60%" }}
          error={emailError}
          helperText={emailHelperText}
          inputRef={idRef}
          onChange={(e) => {
            const target = e.currentTarget;
            setEmail(target.value);
          }}
        />
        <TextField
          autoFocus
          required
          margin="dense"
          id="username"
          name="username"
          label="ID"
          type="username"
          fullWidth
          variant="standard"
          sx={{ width: "60%" }}
          error={nameError}
          helperText={nameHelperText}
          inputRef={idRef}
          onChange={(e) => {
            const target = e.currentTarget;
            setUsername(target.value);
          }}
        />
        <Button
          type="button"
          onClick={checkDuplicate}
          value={"중복확인"}
          sx={{ marginLeft: "20px" }}
        />
        <TextField
          autoFocus
          required
          margin="dense"
          name="password"
          label="Password"
          type="password"
          id="password"
          fullWidth
          variant="standard"
          sx={{
            width: "60%",
          }}
          onChange={(e) => {
            const target = e.currentTarget;
            setPassword(target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          onClick={() => {
            setPopupOpen(false);
          }}
          value={"취소"}
        />
        <Button type="submit" value={"회원가입"} />
      </DialogActions>
    </Dialog>
  );
}

export default JoinHook;
