import React, { forwardRef, useEffect } from "react";
import { Map, Popup } from "react-map-gl";
import SwipeableTextMobileStepper from "../components/SwipeableTextMobileStepper";
import "./Map.css";
import mapStore from "../store/mapStore";
import viewport from "geo-viewport";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiZHJvdzcyNCIsImEiOiJjbGI3dGpiZ3AwZGRvM3NvMnU5a2w3ZHh4In0.Ei81FJmfrOdiB2Rn2rlKyA";

const MapComponent = (
  {
    setLoading,
    bounds = [
      [120.5, 28],
      [134.5, 42],
    ],
    onClick,
    pins,
    children,
    scrollZoom = true,
    dragPan = true,
    doubleClickZoom = true,
  },
  mapRef
) => {
  const { popupInfo, setPopupInfoToNull } = mapStore();

  return (
    <React.Fragment>
      <Map
        initialViewState={{
          bounds: bounds,
          fitBoundsOptions: { padding: 40, duration: 1000 },
        }}
        doubleClickZoom={doubleClickZoom}
        onLoad={(event) => {
          if (bounds) {
            const map = event.target;

            // map dimensions (width, height)
            const mapContainer = map.getContainer();
            const mapDimensions = [
              mapContainer.clientWidth,
              mapContainer.clientHeight,
            ];

            // calculate the viewport using geo-viewport
            const vp = viewport.viewport(
              [...bounds[0], ...bounds[1]],
              mapDimensions
            );

            map.flyTo({
              center: vp.center,
              zoom: vp.zoom - 1,
              duration: 2000,
              essential: true,
            });

            map.on("moveend", () => {
              if (setLoading) {
                setLoading(false);
              }
            });
          }
        }}
        mapboxAccessToken={MAPBOX_TOKEN}
        projection={"globe"}
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        scrollZoom={scrollZoom}
        dragPan={dragPan}
        mapStyle="mapbox://styles/mapbox/dark-v11"
        //interactiveLayerIds={[clusterLayer.id]}
        ref={mapRef}
        onClick={onClick}
      >
        {pins}
        {popupInfo && (
          <React.Fragment>
            <Popup
              anchor="left"
              longitude={Number(popupInfo.location.lng)}
              latitude={Number(popupInfo.location.lat)}
              onClose={setPopupInfoToNull}
              style={{
                width: "100%",
                borderRadius: 10,
                alignItems: "center",
                justifyContent: "space-between",
              }}
              focusAfterOpen={true}
              tip={false}
            >
              <SwipeableTextMobileStepper info={popupInfo} />
            </Popup>
          </React.Fragment>
        )}
        {children}
      </Map>
    </React.Fragment>
  );
};

const wrapper = forwardRef((props, ref) => {
  return MapComponent(props, ref);
});

export default wrapper;
