import { create } from "zustand";
import DEFAULT_ERROR_MESSAGE from "../constants/messages";

const useStore = create((set) => ({
  //1단계에서 upload한 file list state
  files: [],
  setFiles: (value) => set((state) => ({ files: value })),

  location: {},
  setLocation: (value) => set((state) => ({ location: value })),

  caption: "",
  setCaption: (value) => set((state) => ({ caption: value })),

  buttonColor: "primary",
  setButtonColor: (value) => set((state) => ({ buttonColor: value })),

  errorMessage: "",
  setErrorMessage: (value) => set((state) => ({ errorMessage: value })),

  latitude: 37.4012191,
  setLatitude: (value) => set((state) => ({ latitude: value })),

  longitude: 127.1086228,
  setLongitude: (value) => set((state) => ({ longitude: value })),
}));

export default useStore;
