import axios from "axios";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_MAPPIN_API_URL}`,
  withCredentials: true, // 쿠키 cors 통신 설정
});

// access token 재발급
const getRefreshToken = async () => {
  try {
    const res = await instance.get("/login/refreshToken", {
      withCredentials: true, // 쿠키 cors 통신 설정
    });
    const accessToken = res.data.accessToken;
    return accessToken;
  } catch (e) {
    console.error(e);
    // 로그아웃 처리
    //logout();
  }
};

// 요청 인터셉터
instance.interceptors.request.use(
  (config) => {
    // 헤더에 엑세스 토큰 담기
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 응답 인터셉터
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { config, response } = error;
    //  401에러가 아니거나 재요청이거나 refresh 요청인 경우 그냥 에러 발생
    if (
      response.status !== 401 ||
      config.sent ||
      config.url === `/login/refreshToken`
    ) {
      return Promise.reject(error);
    }
    // 아닌 경우 토큰 갱신
    config.sent = true; // 무한 재요청 방지
    const accessToken = await getRefreshToken();
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return axios(config); // 재요청
  }
);

export default instance;
