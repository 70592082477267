import { create } from "zustand";

const useStore = create((set) => ({
  /* 
    given : 사용자 브라우저 localStorage에 longTerm과 accessToken
    when : 과거에 로그인 유지 버튼을 클릭한 상태로 로그인 한 경험이 있을 경우
    then : 사용자 브라우저에 저장된 장기 유지 토큰을 이용하여 로그인한다.
  */
  accessToken:
    localStorage.getItem("longTerm") === "true"
      ? localStorage.getItem("accessToken")
      : null,
  setAccessToken: (value) => set((state) => ({ accessToken: value })),

  //업데이트 가능 여부 -> 24시간에 한번 씩 외부 소셜 미디어에서 정보 가져오기 가능
  updating: false,
  setUpdating: (value) => set((state) => ({ updating: value })),

  /* 
   Mappin에서 사용하는 username외의 instagram에서 사용하는 username;
   instagram_graph_user_profile 권한을 받으려면 백엔드에서
   사용자 인스타그램 profile을 불러와서 사용한더라도, 화면에서 보여야 검수 통과가 가능하다.
  */
  feedId: localStorage.getItem("feedId") || null,
  setFeedId: (value) => set((state) => ({ feedId: value })),

  //Mappin에서 사용하는 username
  username: localStorage.getItem("username") || null,
  setUsername: (value) => set((state) => ({ username: value })),

  email: "",
  setEmail: (value) => set((state) => ({ email: value })),

  joinComplete: false,
  setJoinComplete: (value) => set((state) => ({ joinComplete: value })),

  joinCompleteText: "",
  setJoinCompleteText: (value) => set((state) => ({ joinCompleteText: value })),

  joinCompleteOnClose: () => {},
  setJoinCompleteOnClose: (value) =>
    set((state) => ({ joinCompleteOnClose: value })),
}));

export default useStore;
