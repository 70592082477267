function* autoClick(pins) {
  while (true) {
    const displayIndex = yield;
    click(pins[displayIndex]);
  }
}

function click(pin) {
  // 이벤트 객체를 생성합니다.
  const event = {};

  event.target = {};
  event.target._lngLat = {};
  event.target._lngLat.lng = pin.props.longitude;
  event.target._lngLat.lat = pin.props.latitude;

  pin.props.onClick(event);
}

export default autoClick;
