import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import autoClick from "../../util/Generator";
import mapStore from "../../store/mapStore";

const IOSSwitch = styled((props) => {
  //자동으로 지도상의 컨텐츠들을 순회하는 Mode
  //displayMode를 false로 전환하는 함수
  const { displayMode, setDisplayMode } = mapStore();

  //swipe 이미지를 전부 보여줬을 때 true -> display mode에서 이미지를 전부 다 보여줬을 시 다음 컨텐츠로 넘어갈 수 있도록 하는 flag
  const { isSwipeMax, setSwipeMaxToFalse } = mapStore();

  const { setPopupInfoToNull } = mapStore();

  const { pins } = props;

  const [displayIndex, setDisplayIndex] = useState(0);

  const [autoClickGen, setAutoClickGen] = useState(null);

  useEffect(() => {
    if (autoClickGen) {
      autoClickGen?.next(displayIndex);
    }
  }, [displayIndex]);

  useEffect(() => {
    if (isSwipeMax) {
      setDisplayIndex((cur) => {
        return cur === pins.length - 1 ? 0 : cur + 1;
      });
      setSwipeMaxToFalse();
    }
  }, [isSwipeMax]);

  useEffect(() => {
    if (!autoClickGen && pins.length) {
      const atClick = autoClick(pins);
      atClick.next();
      setAutoClickGen(atClick);
      return;
    }

    if (displayMode && autoClickGen) {
      autoClickGen.next(displayIndex);
    }

    if (!displayMode) {
      setPopupInfoToNull();
    }
  }, [displayMode]);

  return (
    <Switch
      sx={{ m: 1 }}
      checked={displayMode}
      onChange={setDisplayMode}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
    />
  );
})(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default IOSSwitch;
