import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import useStore from "../store/mapStore";

export default function ShareDialog({ url }) {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("sm");
  const { shareDialogFlag, shareDialogClose } = useStore();
  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={shareDialogFlag}
        onClose={shareDialogClose}
      >
        <DialogTitle>공유하기</DialogTitle>
        <DialogContent>
          <DialogContentText>
            링크를 통하여 다른사람에게 공유 가능합니다.
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            <FormControl sx={{ mt: 2, minWidth: 120 }}>
              <TextField
                id="outlined-read-only-input"
                label="URL 링크"
                defaultValue={url}
                InputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={shareDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
