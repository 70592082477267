import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useEffect, useState, Fragment } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useTheme } from "@mui/system";
import { CircularProgress } from "@mui/material";
import mapStore from "../store/mapStore";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SwipeableTextMobileStepper({ info }) {
  //SwipeableText Component를 사용하기 위해 사용하는 mui Hook
  const theme = useTheme();

  //현재 보여주고 있는 이미지 index
  const [activeStep, setActiveStep] = useState(0);

  //최대로 보여줄 수 있는 이미지 갯수
  const maxSteps = info.images.length;

  //로딩이 완료된 이미지 갯수
  const [loadCount, setLoadCount] = useState(0);

  //모든 이미지가 로딩 완료 되었는 지 여부
  const [isLoaded, setIsLoaded] = useState(false);

  //자동으로 지도상의 컨텐츠들을 순회하는 Mode
  //displayMode를 false로 전환하는 함수
  const { displayMode, setDisplayModeToFalse } = mapStore();

  //swipe 이미지를 전부 보여줬을 때 true -> display mode에서 이미지를 전부 다 보여줬을 시 다음 컨텐츠로 넘어갈 수 있도록 하는 flag
  //isSwipeMax false로 전환하는 함수
  const { setSwipeMaxToTrue, setSwipeMaxToFalse } = mapStore();

  // activeStpe state를 1 증가 시키는 함수
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // activeStpe state를 1 감소 시키는 함수
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    if (activeStep === maxSteps - 1) {
      displayMode && setSwipeMaxToTrue();
      return setActiveStep(0);
    }
    setActiveStep(step);
  };

  //popup이 닫혔을 때 초기화 하는 useEffect
  useEffect(() => {
    return () => {
      setActiveStep(0);
      setIsLoaded(false);
      setSwipeMaxToFalse();
      setDisplayModeToFalse();
    };
  }, []);

  useEffect(() => {
    setIsLoaded(loadCount >= maxSteps);
  }, [loadCount]);

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      {
        <Fragment>
          <Paper
            square
            elevation={0}
            sx={{
              display: "flex",
              alignItems: "center",
              height: 50,
              pl: 2,
              bgcolor: "background.default",
            }}
          >
            <Typography>
              {info.location.city ? info.location.city : null}{" "}
              {info.location.city && info.location.name ? "," : null}{" "}
              {info.location.name ? ` ${info.location.name}` : null}
            </Typography>
          </Paper>
          <CircularProgress
            style={{
              display: isLoaded ? "none" : "inline",
              color: "#808080",
            }}
            color="inherit"
          />
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            style={{
              WebkitUserDrag: "none",
              display: isLoaded ? "block" : "none",
            }}
            autoplay={isLoaded}
          >
            {info.images.map((image, i) => {
              return (
                <Box key={i}>
                  <img
                    style={{
                      height: "100%",
                      display: "block",
                      maxWidth: 400,
                      overflow: "hidden",
                      width: "100%",
                      WebkitUserDrag: "none",
                    }}
                    src={`${process.env.REACT_APP_MAPPIN_IMAGE_URL}/${image.url}`}
                    onLoad={() => {
                      setLoadCount((current) => current + 1);
                    }}
                    alt=""
                  />
                </Box>
              );
            })}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
          <Typography variant="body2" gutterBottom>
            {info.caption}
          </Typography>
        </Fragment>
      }
    </Box>
  );
}

export default SwipeableTextMobileStepper;
