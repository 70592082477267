import MapHook from "../hooks/MapHook";
import LoginHook from "../hooks/LoginHook";
import React, { useEffect } from "react";
import userStore from "../store/userStore";
import "./MapRoute.css";
import axios from "../util/AxiosInstance";

const MapRoute = () => {
  /* 
    given : 사용자 브라우저 localStorage에 longTerm과 accessToken
    when : 과거에 로그인 유지 버튼을 클릭한 상태로 로그인 한 경험이 있을 경우
    then : 사용자 브라우저에 저장된 장기 유지 토큰을 이용하여 로그인한다.
  */
  const { accessToken } = userStore();

  const { setJoinComplete, setJoinCompleteText } = userStore();

  //URL 쿼리 parameter를 가져오기 위한 준비
  const currentUrl = window.location.href;

  //URL 쿼리 parameter -> 객체화
  const searchParams = new URL(currentUrl).searchParams;

  const username = searchParams.get("username");

  const authNum = searchParams.get("authNum");

  const { setJoinCompleteOnClose } = userStore();
  useEffect(() => {
    if (username && authNum) {
      axios
        .post(`/join/verify`, {
          username,
          authNum,
        })
        .then((response) => {
          setJoinCompleteText(
            `이메일 인증이 완료되었습니다. \n 로그인하여 서비스를 이용해 주세요.`
          );
          setJoinComplete(true);
          setJoinCompleteOnClose((event, reason) => {
            /* 
              사용자가 content editor를 이용해 장문의 게시글을 작성하던 중 실수로 백그라운드 
              혹은 ecs 키를 잘못 눌러 게시글 작성이 중단되는 경우를 방지하기 위함
            */
            if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
              setJoinComplete(false);
              window.location.href = window.location.href.split("?")[0];
            }
          });
        })
        .catch(() => {});
    }
  }, []);

  /* 
    given : 인스타그램 동기화 하였을 때
    when : 인스타그램 oauth로그인 후 redirect 되었을 때 query parameter에 code라는 이름으로 값을 넘겨줬을 경우
    then : 팝업을 호출한 부모 컨텍스트에 해당 code 내용 넘김
  */
  const code = searchParams.get("code");

  if (code) {
    window.opener.postMessage({ code }, window.location.origin);
    return <div>login.....</div>;
  }

  //메인 화면 첫 진입 시 로그인 유지 했을 경우 바로 지도 화면 아닐 경우 로그인 화면 render
  return accessToken ? <MapHook /> : <LoginHook />;
};

export default MapRoute;
