import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Logout from "@mui/icons-material/Logout";
import SendIcon from "@mui/icons-material/Send";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import mapStore from "../../store/mapStore";
import userStore from "../../store/userStore";

export default function UserMenu() {
  const { anchorEl, anchorElClose } = mapStore();

  const { username } = userStore();
  const { feedId } = userStore();
  const { setAccessToken } = userStore();

  const { setPopup } = mapStore();

  const { shareDialogOpen } = mapStore();

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={Boolean(anchorEl)}
      onClose={anchorElClose}
      onClick={anchorElClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateX(-1600%) translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
    >
      <MenuItem onClick={anchorElClose}>
        <Avatar /> {username}
      </MenuItem>
      <Divider />

      <MenuItem
        disabled={true}
        onClick={() => {
          const popup = window.open(
            `https://api.instagram.com/oauth/authorize?client_id=${process.env.REACT_APP_INSTAGRAM_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_INSTAGRAM_REDIRECT_URL}&response_type=code&scope=user_profile,user_media`,
            "인스타그램 로그인",
            "popup=yes"
          );
          setPopup(popup);
        }}
      >
        <ListItemIcon>
          <CloudSyncIcon fontSize="small" />
        </ListItemIcon>
        {`${feedId ? feedId : "인스타그램 "}연동하기`}
      </MenuItem>
      <MenuItem onClick={shareDialogOpen}>
        <ListItemIcon>
          <SendIcon fontSize="small" />
        </ListItemIcon>
        공유
      </MenuItem>
      <MenuItem
        onClick={() => {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("longTerm");
          setAccessToken(null);
        }}
      >
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        로그아웃
      </MenuItem>
    </Menu>
  );
}
