import React, { useState, useMemo, useEffect } from "react";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import "@toast-ui/editor/dist/toastui-editor.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import "@toast-ui/editor/dist/toastui-editor.css";
import useStore from "../../store/mapStore";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import UploadPicuture from "./UploadPicuture";
import LocationRegistration from "./LocationRegistration";
import WriteCaption from "./WriteCaption";
import postStore from "../../store/postStore";
import mapStore from "../../store/mapStore";
import DEFAULT_ERROR_MESSAGE from "../../constants/messages";
import axios from "../../util/AxiosInstance";
import { DialogActions } from "@mui/material";

const stepTitles = ["사진 업로드", "위치 등록", "캡션 작성"];

const steps = [UploadPicuture, LocationRegistration, WriteCaption];

export default function PostDialog() {
  const { files, setFiles } = postStore();
  const { location, setLocation } = postStore();
  const { caption, setCaption } = postStore();
  const { setButtonColor } = postStore();
  const { setErrorMessage } = postStore();
  const { contents, addContents } = mapStore();
  const [activeStep, setActiveStep] = useState(0);
  const { postDialogFlag, postDialogClose } = useStore();

  const [regConfirm, setRefConfirm] = useState(false);

  // 다음페이지 이동 / 현재페이지에서의 필요한 value값 없을 시 errormsg 띄움
  const handleNext = () => {
    let isSuccess = true;
    if (activeStep === 0 && files.length === 0) {
      setButtonColor("error");
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
      isSuccess = false;
      return;
    }
    if (activeStep === 1 && Object.keys(location).length === 0) {
      setErrorMessage("위치를 추가해주세요.");
      isSuccess = false;
      return;
    }

    if (activeStep === 2) {
      setRefConfirm(true);
      return;
    }

    if (isSuccess) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  // 이전 페이지 이동
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const StepContent = useMemo(() => {
    return steps[activeStep];
  }, [activeStep]);

  const onClose = (event, reason) => {
    /* 
    사용자가 content editor를 이용해 장문의 게시글을 작성하던 중 실수로 백그라운드 
    혹은 ecs 키를 잘못 눌러 게시글 작성이 중단되는 경우를 방지하기 위함
  */
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      postDialogClose();
    }
  };

  // 포스트 등록 - formdata
  const handleContentReg = () => {
    const formData = new FormData();

    files.forEach((file) => formData.append("images", file));
    formData.append("locationId", location.id);
    formData.append("placeName", location.name);
    formData.append("addressName", location.address);
    formData.append("latitude", location.y);
    formData.append("longitude", location.x);
    formData.append("caption", caption);

    axios
      .post("/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        if (response) {
          setRefConfirm(false);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);

          const newContents = [...contents, response.data];

          addContents(response.data);
          //setContents(newContents);
        } else {
          alert("error");
        }
      });
  };

  useEffect(() => {
    return () => {
      setActiveStep(0);
      setFiles([]);
      setLocation({});
      setCaption("");
      setButtonColor("primary");
    };
  }, [postDialogFlag]);

  return (
    <>
      <Dialog
        open={postDialogFlag}
        onClose={onClose}
        maxWidth={"sm"}
        fullWidth
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
          },
        }}
      >
        <DialogTitle fontWeight={"bold"}>포스트 작성</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {stepTitles.map((label, index) => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  포스트 작성이 완료되었습니다! 지도에서 확인 해 보세요
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={postDialogClose}>닫기</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Box sx={{ mt: 2 }}>
                  <StepContent />
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleNext}>
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </Box>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      {regConfirm && (
        <Dialog open={regConfirm}>
          <DialogTitle id="responsive-dialog-title">
            {"등록하시겠습니까?"}
          </DialogTitle>
          <DialogActions>
            <Button autoFocus onClick={() => setRefConfirm(false)}>
              아니요
            </Button>
            <Button autoFocus onClick={handleContentReg}>
              네
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
