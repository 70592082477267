import { create } from "zustand";

const useStore = create((set) => ({
  //자동으로 지도상의 컨텐츠들을 순회하는 Mode
  displayMode: false,
  //displayMode를 반대로 전환
  setDisplayMode: () => set((state) => ({ displayMode: !state.displayMode })),
  //displayMode를 false로 전환하는 함수
  setDisplayModeToFalse: (value) => set((state) => ({ displayMode: false })),
  //displayMode를 true 전환하는 함수
  setDisplayModeToTrue: (value) => set((state) => ({ displayMode: true })),

  //swipe 이미지를 전부 보여줬을 때 true -> display mode에서 이미지를 전부 다 보여줬을 시 다음 컨텐츠로 넘어갈 수 있도록 하는 flag
  isSwipeMax: false,
  //isSwipeMax true 전환하는 함수
  setSwipeMaxToTrue: () => set((state) => ({ isSwipeMax: true })),
  //isSwipeMax false로 전환하는 함수
  setSwipeMaxToFalse: () => set((state) => ({ isSwipeMax: false })),

  //게시글 작성 popup flag
  postDialogFlag: false,
  //게시글 작성 popup open
  postDialogOpen: () => set((state) => ({ postDialogFlag: true })),
  //게시글 작성 popup close
  postDialogClose: () => set((state) => ({ postDialogFlag: false })),

  //유저 상세 메뉴 element
  anchorEl: false,
  //유저 상세 메뉴 닫기
  anchorElClose: () => set((state) => ({ anchorEl: false })),
  //유저 상세 메뉴 element 지정
  setAnchorElByEvent: (event) =>
    set((state) => ({ anchorEl: event.currentTarget })),

  //외부 팝업 정보
  popup: null,
  //외부 팝업 설정
  setPopup: (value) => set((state) => ({ popup: value })),

  //공유 popup open 여부
  shareDialogFlag: false,
  //공유 popup open
  shareDialogOpen: () => set((state) => ({ shareDialogFlag: true })),
  //공유 popup close
  shareDialogClose: () => set((state) => ({ shareDialogFlag: false })),

  //게시글 상세 정보
  popupInfo: null,
  //게시글 상세 정보 설정
  setPopupInfo: (value) => set((state) => ({ popupInfo: value })),
  //게시글 상세 정보 초기화
  setPopupInfoToNull: () => set((state) => ({ popupInfo: null })),

  contents: [],
  setContents: (value) => set((state) => ({ contents: value })),
  addContents: (value) =>
    set((state) => ({ contents: [...state.contents, value] })),
}));

export default useStore;
