// 지도를 오른쪽으로 이동하는 함수
function moveMapRight(mapRef) {
  const current = mapRef.current;
  if (!current) {
    return;
  }
  const center = current.getCenter();

  var newCenter = [center.lng + 0.2, center.lat]; // 오른쪽으로 이동할 좌표 설정
  current.easeTo({ center: newCenter }); // easeTo 메서드를 사용하여 부드럽게 이동
}

export default moveMapRight;
